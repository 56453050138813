
import { defineComponent } from 'vue'
import Checklists from '@/components/Checklists.vue'
import { mapState, mapGetters } from 'vuex'
import store from '@/store'

export default defineComponent({
    name: 'Home',
    components: {
        Checklists
    },
    data () {
        return {
            title: ''
        }
    },
    computed: {
        ...mapState(['checklists']),
        ...mapGetters(['checklistCount', 'openLists', 'completedLists'])
    },
    methods: {
        addChecklist (event: Event) {
            event.preventDefault()
            if (this.title.trim() === '') return
            store.commit('addChecklist', this.title)
            this.title = ''
        }
    }
})
