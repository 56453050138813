
import { defineComponent } from 'vue'
import store from '@/store/index'
import Task from '@/interfaces/task'

export default defineComponent({
    name: 'Task',
    props: ['listId', 'id'],
    data () {
        return {
            done: false
        }
    },
    mounted () {
        this.done = this.task.done
    },
    computed: {
        task (): Task {
            return store.getters.getTask(this.listId, this.id)
        }
    },
    methods: {
        toggleTask () {
            store.commit('doTask', { listId: this.listId, id: this.id, done: this.done })
        }
    }
})
