<template>
    <div class="header">
        <h1>Checklists</h1>
        <form class="addList" @submit="addChecklist">
            <input class="addList-input" :class="{ 'addList-input--focus': title !== '' }" type="text" v-model="title" />
            <button class="addList-btn"></button>
        </form>
    </div>
    <Checklists />
    <!-- pre>{{ checklists }}</pre -->
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Checklists from '@/components/Checklists.vue'
import { mapState, mapGetters } from 'vuex'
import store from '@/store'

export default defineComponent({
    name: 'Home',
    components: {
        Checklists
    },
    data () {
        return {
            title: ''
        }
    },
    computed: {
        ...mapState(['checklists']),
        ...mapGetters(['checklistCount', 'openLists', 'completedLists'])
    },
    methods: {
        addChecklist (event: Event) {
            event.preventDefault()
            if (this.title.trim() === '') return
            store.commit('addChecklist', this.title)
            this.title = ''
        }
    }
})
</script>

<style lang="scss">
    .header {
        @extend .container;
        background: #fff;
        border-radius: 0 0 $radius $radius;
        margin-bottom: $space-200;
        padding-top: $space-200;
        padding-bottom: $space;
        h1 {
            font-size: em(35);
            font-weight: 600;
            margin-bottom: $space-200;
        }
    }

    .addList {
        display: flex;
        &-input {
            display: flex;
            flex: 1;
            color: $text-color;
            border: 0;
            background: url('../assets/img/plus.svg') center / em(18) no-repeat scale-color($color1, $lightness: 80%);
            padding: $space-50 $space $space-50 $space-50;
            border-radius: $radius-2;
            transition: background-color ease .25s;
            &:focus,
            &--focus {
                margin-right: $space;
                background: $bg-color;
            }
        }
        &-input:focus + &-btn,
        &-input--focus + &-btn {
            display: flex;
        }
        &-btn {
            cursor: pointer;
            display: none;
            border: 0;
            background: url('../assets/img/plus-white.svg') center / em(18) no-repeat $color1;
            width: em(25);
            padding: $space-50;
            box-sizing: content-box;
            border-radius: $radius-2;
        }
    }
</style>
