
// https://codepen.io/xgad/post/svg-radial-progress-meters

import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ProgressCircle',
    props: ['size', 'stroke', 'progress'],
    computed: {
        radius (): number {
            return this.size / 2 - this.stroke / 2
        },
        dasharray (): number {
            return 2 * Math.PI * this.radius
        },
        percent (): number {
            const percent = Math.random()
            return this.dasharray * (1 - this.progress)
        }
    }
})
