
import { defineComponent } from 'vue'
import store from '@/store/index'
import Checklist from '@/interfaces/checklist'
import TaskInterface from '@/interfaces/task'
import Task from '@/components/Task.vue'
import ProgressCircle from '@/components/ProgressCircle.vue'

export default defineComponent({
    name: 'Checklist',
    components: {
        Task,
        ProgressCircle
    },
    props: ['id'],
    data () {
        return {
            title: '',
            showDetail: false
        }
    },
    computed: {
        checklist (): Checklist {
            return store.getters.getChecklist(this.id)
        },
        tasks (): TaskInterface[] {
            return this.checklist.tasks || []
        },
        doneTasks (): TaskInterface[] {
            return store.getters.getDoneTasks(this.id)
        },
        progress () {
            return this.tasks.length ? this.doneTasks.length / this.tasks.length : 0
        }
    },
    methods: {
        addTask (event: Event) {
            event.preventDefault()
            if (this.title.trim() === '') return
            store.commit('addTask', { listId: this.id, title: this.title })
            this.title = ''
        },
        copy () {
            const clearChecks = confirm('Uncheck done tasks?')
            const title = prompt('Change title?', this.checklist.title)
            store.commit('copyList', { listId: this.id, title, clearChecks })
        },
        remove () {
            const deleteIt = confirm(`Delete List ${this.checklist.title}?`)
            if (deleteIt) store.commit('removeList', this.id)
        }
    }
})
