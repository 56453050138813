<template>
    <div class="container">
        <transition-group name="overview" tag="div">
            <Checklist class="overview-item" v-for="checklist in getOrderedLists" :style="{ zIndex: checklist.updatedAt % 100000000 }" :id="checklist.id" :key="checklist.id"></Checklist>
        </transition-group>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import Checklist from '@/components/Checklist.vue'

export default defineComponent({
    name: 'Checklists',
    components: {
        Checklist
    },
    computed: {
        ...mapGetters(['getOrderedLists'])
    }
})
</script>

<style lang="scss">
    .overview-item {
        transition: all 1s;
        position: relative;
    }
    .overview-enter, .overview-leave-to {
        opacity: 0;
        transform: translateY(100px);
    }
    .overview-leave-active {
        position: absolute;
    }
</style>
