<template>
    <div class="task">
        <input type="checkbox" @change="toggleTask" v-model="done" :id="`task-${listId}-${id}`" />
        <label class="task-checkbox" :class="{ 'task-checkbox--done': task.done }" :for="`task-${listId}-${id}`"></label>
        <div class="task-content">
            <div class="task-title" :class="{ 'task-title--done': task.done }">{{ task.title }}</div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import store from '@/store/index'
import Task from '@/interfaces/task'

export default defineComponent({
    name: 'Task',
    props: ['listId', 'id'],
    data () {
        return {
            done: false
        }
    },
    mounted () {
        this.done = this.task.done
    },
    computed: {
        task (): Task {
            return store.getters.getTask(this.listId, this.id)
        }
    },
    methods: {
        toggleTask () {
            store.commit('doTask', { listId: this.listId, id: this.id, done: this.done })
        }
    }
})
</script>

<style lang="scss">
    .task {
        display: flex;
        margin-bottom: $space;
        border-radius: $radius-2;
        input[type="checkbox"] {
            display: none;
        }
        &-checkbox {
            display: inline-block;
            width: em(28);
            height: em(28);
            border-radius: 50%;
            border: 2px solid $color1;
            cursor: pointer;
            margin-right: $space;
            position: relative;
            &--done {
                background: $color1;
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    background: url('../assets/img/check.svg') center / em(14) no-repeat;
                }
            }
        }
        &-title {
            transition: all ease .5s;
            &--done {
                opacity: .5;
                text-decoration: line-through;
            }
        }
        &-content {
            flex: 1;
            padding-top: em(5);
        }
    }
</style>
