
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import Checklist from '@/components/Checklist.vue'

export default defineComponent({
    name: 'Checklists',
    components: {
        Checklist
    },
    computed: {
        ...mapGetters(['getOrderedLists'])
    }
})
