import { createStore } from 'vuex'
import Checklist from '@/interfaces/checklist'
import { cloneDeep, orderBy } from 'lodash'
import VuexPersistence from 'vuex-persist'

const mockState: Checklist[] = [
    {
        id: 1,
        createdAt: 1,
        updatedAt: 1,
        title: 'Sommerurlaub',
        tasks: [
            {
                id: 1,
                title: 'Badelatschen',
                done: false
            },
            {
                id: 2,
                title: 'Sonnencreme',
                done: true
            },
            {
                id: 3,
                title: 'Handtuch',
                done: false
            }
        ]
    },
    {
        id: 3,
        createdAt: 3,
        updatedAt: 3,
        title: 'Sommerurlaub 2019',
        tasks: [
            {
                id: 1,
                title: 'Badelatschen',
                done: true
            },
            {
                id: 2,
                title: 'Sonnencreme',
                done: true
            }
        ]
    },
    {
        id: 2,
        createdAt: 2,
        updatedAt: 2,
        title: 'Winterurlaub'
    }
]

const persist = new VuexPersistence({
    key: 'recheck-app-data'
})

export default createStore({
    plugins: [persist.plugin],
    state: {
        checklists: mockState
    },
    mutations: {
        addChecklist (state, title: string) {
            state.checklists.unshift({
                id: Date.now(),
                title,
                createdAt: Date.now(),
                updatedAt: Date.now()
            })
        },
        addTask (state, { listId, title }) {
            const list = state.checklists.find(checklist => checklist.id === listId)
            if (!list) return
            list.tasks = list.tasks || []
            list.tasks.push({
                id: Date.now(),
                title,
                done: false
            })
            list.updatedAt = Date.now()
        },
        doTask (state, { listId, id, done }) {
            const list = state.checklists.find(checklist => checklist.id === listId)
            const task = list?.tasks?.find(task => task.id === id)
            if (task) task.done = done
            if (list) list.updatedAt = Date.now()
        },
        copyList (state, { listId, title, clearChecks }) {
            const list = state.checklists.find(checklist => checklist.id === listId)
            const newList = cloneDeep(list) as Checklist
            newList.title = title
            newList.id = Date.now()
            newList.createdAt = Date.now()
            newList.updatedAt = Date.now()
            if (clearChecks && newList.tasks) newList.tasks.forEach(task => { task.done = false })
            state.checklists.unshift(newList)
        },
        removeList (state, listId: number) {
            const listIndex = state.checklists.findIndex(checklist => checklist.id === listId)
            state.checklists.splice(listIndex, 1)
        }
    },
    actions: {},
    modules: {},
    getters: {
        getChecklist: state => (id: number) => {
            return state.checklists.find(checklist => checklist.id === id)
        },
        getOrderedLists: state => {
            return orderBy(state.checklists, [list => {
                if (!list.tasks) return false
                return list.tasks?.every(task => {
                    return task.done
                })
            }, 'updatedAt'], ['asc', 'desc'])
        },
        openLists: state => {
            return state.checklists.filter(checklist => {
                if (!checklist.tasks) return true
                return checklist.tasks.some(task => {
                    return !task.done
                })
            })
        },
        completedLists: state => {
            return state.checklists.filter(checklist => {
                return checklist.tasks?.every(task => {
                    return task.done
                })
            })
        },

        getTask: state => (listId: number, id: number) => {
            const list = state.checklists.find(checklist => checklist.id === listId)
            return list?.tasks?.find(task => task.id === id)
        },
        getDoneTasks: state => (listId: number) => {
            const list = state.checklists.find(checklist => checklist.id === listId)
            return list?.tasks?.filter(task => task.done) || []
        },
        checklistCount: state => {
            return state.checklists.length
        },
        checklistComplete: state => (listId: number) => {
            const list = state.checklists.find(checklist => checklist.id === listId)
            return list?.tasks?.every(task => task.done)
        }
    }
})
