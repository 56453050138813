<template>
    <div class="checklist">
        <div class="checklist-header" @click="showDetail = !showDetail">
            <div class="checklist-progress" :class="{ 'checklist-progress--done': tasks.length && doneTasks.length === tasks.length }">
                <ProgressCircle :size="40" :stroke="tasks.length && doneTasks.length === tasks.length ? 20 : 4" :progress="progress" />
            </div>
            <div class="checklist-titleContainer">
                <h2 class="checklist-title">{{ checklist.title }}</h2>
                <div class="checklist-count">{{ doneTasks.length }} / {{ tasks.length }}</div>
            </div>
            <!--div v-show="showDetail">
                <button @click="copy">Copy</button>
                <button @click="remove">Remove</button>
            </div-->
        </div>
        <div class="checklist-detail" v-show="showDetail">
            <Task v-for="task in checklist.tasks" :list-id="checklist.id" :id="task.id" :key="task.id"></Task>
            <form class="addTask" @submit="addTask">
                <input class="addTask-input" :class="{ 'addTask-input--focus': title !== '' }" type="text" v-model="title" />
                <button class="addTask-btn"></button>
            </form>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import store from '@/store/index'
import Checklist from '@/interfaces/checklist'
import TaskInterface from '@/interfaces/task'
import Task from '@/components/Task.vue'
import ProgressCircle from '@/components/ProgressCircle.vue'

export default defineComponent({
    name: 'Checklist',
    components: {
        Task,
        ProgressCircle
    },
    props: ['id'],
    data () {
        return {
            title: '',
            showDetail: false
        }
    },
    computed: {
        checklist (): Checklist {
            return store.getters.getChecklist(this.id)
        },
        tasks (): TaskInterface[] {
            return this.checklist.tasks || []
        },
        doneTasks (): TaskInterface[] {
            return store.getters.getDoneTasks(this.id)
        },
        progress () {
            return this.tasks.length ? this.doneTasks.length / this.tasks.length : 0
        }
    },
    methods: {
        addTask (event: Event) {
            event.preventDefault()
            if (this.title.trim() === '') return
            store.commit('addTask', { listId: this.id, title: this.title })
            this.title = ''
        },
        copy () {
            const clearChecks = confirm('Uncheck done tasks?')
            const title = prompt('Change title?', this.checklist.title)
            store.commit('copyList', { listId: this.id, title, clearChecks })
        },
        remove () {
            const deleteIt = confirm(`Delete List ${this.checklist.title}?`)
            if (deleteIt) store.commit('removeList', this.id)
        }
    }
})
</script>

<style lang="scss">
    .checklist {
        background: #fff;
        box-shadow: 0 1px 20px transparentize($color1, .95);
        border-radius: $radius;
        padding: $space;
        margin-bottom: $space;
        &-header {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
        &-count {
            font-size: em(14);
            font-weight: 300;
            margin-top: $space-10;
        }
        &-titleContainer {
            overflow: hidden;
        }
        &-title {
            font-size: em(22);
            font-weight: 600;
            // single line
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;

        }
        &-progress {
            flex: none;
            margin-right: $space-75;
            position: relative;
            width: em(40);
            height: em(40);
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background: url('../assets/img/check.svg') center / em(20) no-repeat;
                opacity: 0;
                transition: opacity ease .75s;
            }
            &--done::before {
                opacity: 1;
            }
        }
        &-detail {
            padding-top: $space;
            margin-top: $space;
            border-top: 1px solid transparentize($color1, .85);
        }
    }

    .addTask {
        display: flex;
        align-items: stretch;
        &-input {
            display: flex;
            flex: 1;
            color: $text-color;
            border: 0;
            background: transparent;
            background: url('../assets/img/plus.svg') center / em(18) no-repeat scale-color($color1, $lightness: 80%);
            padding: $space-50 $space $space-50 $space-50;
            border-radius: $radius-2;
            transition: background-color ease .25s;
            &:focus,
            &--focus {
                margin-right: $space;
                background: $bg-color;
            }
        }
        &-input:focus + &-btn,
        &-input--focus + &-btn {
            display: flex;
        }
        &-btn {
            cursor: pointer;
            display: none;
            border: 0;
            background: url('../assets/img/plus-white.svg') center / em(18) no-repeat $color1;
            width: em(25);
            padding: $space-50;
            box-sizing: content-box;
            border-radius: $radius-2;
        }
    }
</style>
