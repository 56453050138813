<template>
    <svg class="progress" :width="size" :height="size" :viewBox="`0 0 ${size} ${size}`">
        <circle class="progress-background" :cx="size/2" :cy="size/2" :r="radius" fill="none" :stroke-width="stroke" />
        <circle class="progress-bar" :cx="size/2" :cy="size/2" :r="radius" fill="none" :stroke-width="stroke" :stroke-dasharray="dasharray" :stroke-dashoffset="percent" stroke-linecap="round" />
    </svg>
</template>

<script lang="ts">
// https://codepen.io/xgad/post/svg-radial-progress-meters

import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ProgressCircle',
    props: ['size', 'stroke', 'progress'],
    computed: {
        radius (): number {
            return this.size / 2 - this.stroke / 2
        },
        dasharray (): number {
            return 2 * Math.PI * this.radius
        },
        percent (): number {
            const percent = Math.random()
            return this.dasharray * (1 - this.progress)
        }
    }
})
</script>

<style lang="scss">
    .progress {
        display: block;
        width: 100%;
        height: 100%;
        &-background {
            stroke: transparentize($color1, .85);
            transition: all ease .75s;
        }
        &-bar {
            stroke: $color1;
            transition: all ease .75s;
            transform-origin: center;
            transform: rotate(90deg);
        }
    }
</style>
